var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "empty-topic-placeholder-display";
},"3":function(container,depth0,helpers,partials,data) {
    return "placeholder=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "empty_string_topic_display_name", {"start":{"line":6,"column":55},"end":{"line":6,"column":86}} ), depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<form id=\"topic_edit_form\">\n    <input type=\"text\" value=\"\" autocomplete=\"off\" maxlength=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "max_topic_length", {"start":{"line":4,"column":65},"end":{"line":4,"column":81}} ), depth0))
    + "\"\n      class=\"inline_topic_edit header-v "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"realm_mandatory_topics"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":116}}})) != null ? stack1 : "")
    + "\"\n      "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"realm_mandatory_topics"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":100}}})) != null ? stack1 : "")
    + " />\n    <button type=\"button\" class=\"topic_edit_save small_square_button animated-purple-button\"><i class=\"fa fa-check\" aria-hidden=\"true\"></i></button>\n    <button type=\"button\" class=\"topic_edit_cancel small_square_button small_square_x\"><i class=\"fa fa-remove\" aria-hidden=\"true\"></i></button>\n    <div class=\"alert alert-error edit_error\" style=\"display: none\"></div>\n    <div class=\"topic_edit_spinner\"></div>\n</form>\n";
},"useData":true});