var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <label for=\"change_user_group_description\" class=\"modal-field-label\">\n        "
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"User group description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":39}}}))
    + "\n    </label>\n    <textarea id=\"change_user_group_description\" class=\"settings_textarea\" name=\"user_group_description\">"
    + alias1(container.lambda(container.strict(depth0, "group_description", {"start":{"line":13,"column":108},"end":{"line":13,"column":125}} ), depth0))
    + "</textarea>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <label for=\"change_user_group_name\" class=\"modal-field-label\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":32}}}))
    + "\n    </label>\n    <input type=\"text\" id=\"change_user_group_name\" class=\"modal_text_input\" name=\"user_group_name\" value=\""
    + alias2(alias4(alias3(depth0, "group_name", {"start":{"line":5,"column":109},"end":{"line":5,"column":119}} ), depth0))
    + "\" maxlength=\""
    + alias2(alias4(alias3(depth0, "max_user_group_name_length", {"start":{"line":5,"column":137},"end":{"line":5,"column":163}} ), depth0))
    + "\" />\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"allow_editing_description"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":0},"end":{"line":15,"column":7}}})) != null ? stack1 : "");
},"useData":true});