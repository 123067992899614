var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"option-row\">\n    <i class=\"zulip-icon zulip-icon-grip-vertical drag-icon\"></i>\n    <input type=\"text\" class=\"todo-input modal_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"New task",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":72},"end":{"line":3,"column":88}}}))
    + "\" />\n    <div class=\"todo-description-container\">\n        <input type=\"text\" class=\"todo-description-input modal_text_input\" disabled=\"true\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Task description (optional)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":104},"end":{"line":5,"column":139}}}))
    + "\" />\n    </div>\n    <button type=\"button\" class=\"button rounded small btn-secondary delete-option\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":90},"end":{"line":7,"column":105}}}))
    + "\">\n        <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n    </button>\n</li>\n";
},"useData":true});