var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "href=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "above_composebox_narrow_url", {"start":{"line":4,"column":95},"end":{"line":4,"column":122}} ), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"message_recipient"),{"name":"with","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":15,"column":25}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"message_type"),"channel",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":49}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":14,"column":23}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":11,"column":27}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        Go to #{channel_name} &gt; <z-topic-display-name></z-topic-display-name>\n                        \n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":65},"end":{"line":10,"column":128}}})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "topic_display_name", {"start":{"line":10,"column":131},"end":{"line":10,"column":149}} ), depth0))
    + "</span>";
},"8":function(container,depth0,helpers,partials,data) {
    return "class=\"empty-topic-display\"";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Go to {recipient_text}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":51}}}))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                "
    + container.escapeExpression(container.lambda(container.strict(depth0, "link_text", {"start":{"line":17,"column":18},"end":{"line":17,"column":27}} ), depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"above_compose_banner main-view-banner success "
    + alias3(alias2(alias1(depth0, "classname", {"start":{"line":1,"column":60},"end":{"line":1,"column":69}} ), depth0))
    + "\">\n    <p class=\"banner_content\">\n        "
    + alias3(alias2(alias1(depth0, "banner_text", {"start":{"line":3,"column":10},"end":{"line":3,"column":21}} ), depth0))
    + "\n        <a class=\"above_compose_banner_action_link\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"above_composebox_narrow_url"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":52},"end":{"line":4,"column":132}}})) != null ? stack1 : "")
    + " data-message-id=\""
    + alias3(alias2(alias1(depth0, "link_msg_id", {"start":{"line":4,"column":152},"end":{"line":4,"column":163}} ), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"message_recipient"),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n    </p>\n    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n</div>\n";
},"6_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-topic-display-name"],"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":169}}}) || fn;
  return fn;
  }

,"useDecorators":true,"useData":true,"useDepths":true});