var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            Zulip lets you follow topics you are interested in, and mute topics you want to ignore.\n            You can also <z-automatically-follow>automatically follow</z-automatically-follow>\n            topics you start or participate in, and topics where you're mentioned.\n            \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/follow-a-topic#automatically-follow-topics\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"user-topic-settings\" class=\"settings-section\" data-name=\"topics\">\n    <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </p>\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":34}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/topic-notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </h3>\n        "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":true,"section_name":"user-topics-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./filter_text_input.hbs"),undefined,{"name":"filter_text_input","hash":{"aria_label":lookupProperty(helpers,"t").call(alias1,"Filter invitations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":90},"end":{"line":15,"column":114}}}),"placeholder":lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":66},"end":{"line":15,"column":78}}}),"id":"user_topics_search"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th data-sort=\"alphabetic\" data-sort-prop=\"stream\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":67},"end":{"line":20,"column":83}}}))
    + "</th>\n                <th data-sort=\"alphabetic\" data-sort-prop=\"topic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":66},"end":{"line":21,"column":80}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"visibility_policy\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":75},"end":{"line":22,"column":90}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"date_updated\" class=\"active topic_date_updated\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Date updated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":104},"end":{"line":23,"column":125}}}))
    + "</th>\n            </thead>\n            <tbody id=\"user_topics_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have not configured any topics yet.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":54},"end":{"line":25,"column":101}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No topics match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":130},"end":{"line":25,"column":175}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-automatically-follow"],"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":185}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});