var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "id", {"start":{"line":3,"column":29},"end":{"line":3,"column":31}} ), depth0))
    + "\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = container.lambda(container.strict(depth0, "html_heading", {"start":{"line":7,"column":28},"end":{"line":7,"column":40}} ), depth0)) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                        "
    + container.escapeExpression(container.lambda(container.strict(depth0, "text_heading", {"start":{"line":9,"column":27},"end":{"line":9,"column":39}} ), depth0))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = container.invokePartial(require("./help_link_widget.hbs"),depth0,{"name":"help_link_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "data-simplebar-auto-hide=\"false\"";
},"11":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"dialog-widget-footer-minor-text\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "footer_minor_text", {"start":{"line":23,"column":63},"end":{"line":23,"column":80}} ), depth0))
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"modal__button dialog_exit_button\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Close this dialog window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":77},"end":{"line":26,"column":110}}}))
    + "\" data-micromodal-close>"
    + ((stack1 = container.lambda(container.strict(depth0, "html_exit_button", {"start":{"line":26,"column":138},"end":{"line":26,"column":154}} ), depth0)) != null ? stack1 : "")
    + "</button>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Close this dialog window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":111},"end":{"line":29,"column":144}}}))
    + "\" data-micromodal-close";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\""
    + alias3(alias2(alias1(depth0, "modal_unique_id", {"start":{"line":1,"column":30},"end":{"line":1,"column":45}} ), depth0))
    + "\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"id"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":42}}})) != null ? stack1 : "")
    + "class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"dialog_title\">\n            <header class=\"modal__header\">\n                <h1 class=\"modal__title dialog_heading\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"html_heading"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":10,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"link"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":13,"column":27}}})) != null ? stack1 : "")
    + "                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":57},"end":{"line":15,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </header>\n            <main class=\"modal__content\" data-simplebar data-simplebar-tab-index=\"-1\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"always_visible_scrollbar"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":86},"end":{"line":17,"column":157}}})) != null ? stack1 : "")
    + ">\n                <div class=\"alert\" id=\"dialog_error\"></div>\n                "
    + ((stack1 = alias2(alias1(depth0, "html_body", {"start":{"line":19,"column":20},"end":{"line":19,"column":29}} ), depth0)) != null ? stack1 : "")
    + "\n            </main>\n            <footer class=\"modal__footer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"footer_minor_text"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"single_footer_button"),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":27,"column":27}}})) != null ? stack1 : "")
    + "                <div class=\"dialog_submit_button_container\">\n                    <button class=\"modal__button dialog_submit_button\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"single_footer_button"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":70},"end":{"line":29,"column":174}}})) != null ? stack1 : "")
    + ">\n                        <span class=\"submit-button-text\">"
    + ((stack1 = alias2(alias1(depth0, "html_submit_button", {"start":{"line":30,"column":61},"end":{"line":30,"column":79}} ), depth0)) != null ? stack1 : "")
    + "</span>\n                        <div class=\"modal__spinner\"></div>\n                    </button>\n                </div>\n            </footer>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});