var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group setting-next-is-related "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_disabled"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":48},"end":{"line":2,"column":96}}})) != null ? stack1 : "")
    + "\">\n    <label for=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"prefix"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":47}}})) != null ? stack1 : "")
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":3,"column":49},"end":{"line":3,"column":61}} ), depth0))
    + "\" class=\""
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":3,"column":74},"end":{"line":3,"column":86}} ), depth0))
    + "_label\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"prefix"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":100},"end":{"line":3,"column":131}}})) != null ? stack1 : "")
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":3,"column":133},"end":{"line":3,"column":145}} ), depth0))
    + "_label\">\n        "
    + alias4(alias3(alias2(depth0, "label", {"start":{"line":4,"column":10},"end":{"line":4,"column":15}} ), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"label_parens_text"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"help_link"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"help_icon_tooltip_text"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "    </label>\n    <input type=\"text\" inputmode=\"numeric\" pattern=\"\\d*\" value=\""
    + alias4(alias3(alias2(depth0, "setting_value", {"start":{"line":15,"column":66},"end":{"line":15,"column":79}} ), depth0))
    + "\" class=\""
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":15,"column":92},"end":{"line":15,"column":104}} ), depth0))
    + " settings_text_input setting-widget prop-element\" name=\""
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":15,"column":164},"end":{"line":15,"column":176}} ), depth0))
    + "\" data-setting-widget-type=\"number\"\n      id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"prefix"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":10},"end":{"line":16,"column":41}}})) != null ? stack1 : "")
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":16,"column":43},"end":{"line":16,"column":55}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_disabled"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":59},"end":{"line":16,"column":93}}})) != null ? stack1 : "")
    + " />\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "control-label-disabled";
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "prefix", {"start":{"line":3,"column":32},"end":{"line":3,"column":38}} ), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    return "        (<i>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "label_parens_text", {"start":{"line":6,"column":14},"end":{"line":6,"column":31}} ), depth0))
    + "</i>)\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":lookupProperty(depth0,"help_link")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"tippy-zulip-tooltip fa fa-info-circle settings-info-icon\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"hide_tooltip"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":76},"end":{"line":12,"column":125}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "help_icon_tooltip_text", {"start":{"line":12,"column":148},"end":{"line":12,"column":170}} ), depth0))
    + "\"></i>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"render_only"),false,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":1,"column":10},"end":{"line":1,"column":32}}}),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":18,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});