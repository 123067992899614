var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":52}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-active-users-list\" class=\"user-settings-section\" data-user-settings-section=\"active\">\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":25}}}))
    + "</h3>\n        <div class=\"alert-notification\" id=\"user-field-status\"></div>\n        <div class=\"user_filters\">\n            "
    + ((stack1 = container.invokePartial(require("../dropdown_widget.hbs"),undefined,{"name":"../dropdown_widget","hash":{"widget_name":lookupProperty(depth0,"active_user_list_dropdown_widget_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            "
    + ((stack1 = container.invokePartial(require("./filter_text_input.hbs"),undefined,{"name":"filter_text_input","hash":{"aria_label":lookupProperty(helpers,"t").call(alias1,"Filter users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":70},"end":{"line":8,"column":88}}}),"placeholder":lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":46},"end":{"line":8,"column":58}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar data-simplebar-tab-index=\"-1\">\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":85},"end":{"line":15,"column":98}}}))
    + "</th>\n                <th class=\"settings-email-column\" data-sort=\"email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":68},"end":{"line":16,"column":82}}}))
    + "</th>\n                <th class=\"user_role\" data-sort=\"role\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":55},"end":{"line":17,"column":68}}}))
    + "</th>\n                <th class=\"last_active\" data-sort=\"last_active\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Last active",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":64},"end":{"line":18,"column":84}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody id=\"admin_users_table\" class=\"admin_user_table\"\n              data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No users match your filters.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":26},"end":{"line":24,"column":63}}}))
    + "\"></tbody>\n        </table>\n    </div>\n    <div id=\"admin_page_users_loading_indicator\"></div>\n</div>\n";
},"usePartial":true,"useData":true});