var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu navbar-banners-testing-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"desktop-notifications popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Desktop notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":49},"end":{"line":6,"column":78}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"configure-outgoing-mail popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Configure outgoing mail",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":80}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"insecure-desktop-app popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Insecure desktop app",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":49},"end":{"line":18,"column":77}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"profile-missing-required-fields popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Profile missing required fields",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":49},"end":{"line":24,"column":88}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"organization-profile-incomplete popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization profile incomplete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":49},"end":{"line":30,"column":88}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"server-needs-upgrade popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Server needs upgrade",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":49},"end":{"line":36,"column":77}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"bankruptcy popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Bankruptcy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":49},"end":{"line":42,"column":67}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"demo-organization-deadline popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Demo organization deadline",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":49},"end":{"line":48,"column":83}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <a role=\"menuitem\" class=\"time_zone_update_offer popover-menu-link\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time zone update offer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":49},"end":{"line":54,"column":79}}}))
    + "</span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});