var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"above_compose_banner main-view-banner warning-style "
    + alias3(alias2(alias1(depth0, "classname", {"start":{"line":1,"column":66},"end":{"line":1,"column":75}} ), depth0))
    + "\">\n    <p class=\"banner_content\">\n        "
    + alias3(alias2(alias1(depth0, "banner_text", {"start":{"line":3,"column":10},"end":{"line":3,"column":21}} ), depth0))
    + "\n    </p>\n    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n</div>\n";
},"useData":true});