var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"none\" class=\"link-item popover-menu-list-item condensed-views-popover-menu-scheduled-messages\">\n            <a href=\"#scheduled\" role=\"menuitem\" class=\"popover-menu-link tippy-left-sidebar-tooltip\" tabindex=\"0\" data-tooltip-template-id=\"scheduled-tooltip-template\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-calendar-days\" aria-hidden=\"true\"></i>\n                <span class=\"label-and-unread-wrapper\">\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Scheduled messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":53},"end":{"line":23,"column":80}}}))
    + "</span>\n                    <span class=\"unread_count quiet-count\"></span>\n                </span>\n            </a>\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list condensed-views-popover-menu\">\n        <li role=\"none\" class=\"link-item popover-menu-list-item condensed-views-popover-menu-reactions\">\n            <a href=\"#narrow/has/reaction/sender/me\" role=\"menuitem\" class=\"popover-menu-link tippy-left-sidebar-tooltip\" data-tooltip-template-id=\"my-reactions-tooltip-template\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-smile\" aria-hidden=\"true\"></i>\n                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reactions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":49},"end":{"line":6,"column":67}}}))
    + "</span>\n            </a>\n        </li>\n        <li role=\"none\" class=\"link-item popover-menu-list-item condensed-views-popover-menu-drafts\">\n            <a href=\"#drafts\" role=\"menuitem\" class=\"popover-menu-link tippy-left-sidebar-tooltip\" data-tooltip-template-id=\"drafts-tooltip-template\" tabindex=\"0\">\n                <i class=\"popover-menu-icon zulip-icon zulip-icon-drafts\" aria-hidden=\"true\"></i>\n                <span class=\"label-and-unread-wrapper\">\n                    <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":53},"end":{"line":13,"column":68}}}))
    + "</span>\n                    <span class=\"unread_count quiet-count\"></span>\n                </span>\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_scheduled_messages"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});