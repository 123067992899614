var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"alert\" id=\"dev_env_msg\"></div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tip\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            You must <z-link>configure your email</z-link> to access this feature.\n            \n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/demo-organizations#configure-email-for-demo-organization-owner\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"default"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":24},"end":{"line":34,"column":60}}})) != null ? stack1 : "")
    + " value=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":34,"column":70},"end":{"line":34,"column":80}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":34,"column":86},"end":{"line":34,"column":102}} ), depth0))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "selected";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option value=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":43,"column":37},"end":{"line":43,"column":46}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":43,"column":52},"end":{"line":43,"column":68}} ), depth0))
    + "</option>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias3(alias2(alias1(lookupProperty(lookupProperty(depth0,"invite_as_options"),"moderator"), "code", {"start":{"line":57,"column":30},"end":{"line":57,"column":62}} ), depth0))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Moderators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":67},"end":{"line":57,"column":86}}}))
    + "</option>\n            <option value=\""
    + alias3(alias2(alias1(lookupProperty(lookupProperty(depth0,"invite_as_options"),"admin"), "code", {"start":{"line":58,"column":30},"end":{"line":58,"column":58}} ), depth0))
    + "\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Administrators",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":63},"end":{"line":58,"column":86}}}))
    + "</option>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias1(container.lambda(container.strict(lookupProperty(lookupProperty(depth0,"invite_as_options"),"owner"), "code", {"start":{"line":61,"column":30},"end":{"line":61,"column":58}} ), depth0))
    + "\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Owners",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":63},"end":{"line":61,"column":78}}}))
    + "</option>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"select_default_streams\">\n                <label class=\"checkbox display-block modal-field-label\">\n                    <input type=\"checkbox\" id=\"invite_select_default_streams\" checked=\"checked\" />\n                    <span class=\"rendered-checkbox\"></span>\n                    "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Default channels for this organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":20},"end":{"line":73,"column":66}}}))
    + "\n                </label>\n            </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group\">\n            <label class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User groups they should join",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":45},"end":{"line":91,"column":82}}}))
    + " "
    + ((stack1 = container.invokePartial(require("./help_link_widget.hbs"),undefined,{"name":"help_link_widget","hash":{"link":"/help/user-groups"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</label>\n            <div id=\"user-groups-to-add\">\n                <div id=\"invite-user-group-container\" class=\"add-user-group-container\">\n                    <div class=\"pill-container\">\n                        <div class=\"input\" contenteditable=\"true\"\n                          data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add user groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":44},"end":{"line":96,"column":68}}}))
    + "\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"invite-user-form\">\n    <div class=\"setup-tips-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":72}}})) != null ? stack1 : "")
    + "\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"development_environment"),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":34}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"input-group\">\n        <div id=\"invite_users_option_tabs_container\"></div>\n        <div id=\"invitee_emails_container\">\n            <label for=\"invitee_emails\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emails (one on each line or comma-separated)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":66},"end":{"line":17,"column":119}}}))
    + "</label>\n            <div class=\"pill-container\">\n                <div class=\"input\" contenteditable=\"true\"></div>\n            </div>\n        </div>\n    </div>\n    <div class=\"input-group\" id=\"receive-invite-acceptance-notification-container\">\n        <label class=\"checkbox display-block\">\n            <input type=\"checkbox\" id=\"receive-invite-acceptance-notification\" checked/>\n            <span class=\"rendered-checkbox\"></span>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send me a direct message when my invitation is accepted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":76}}}))
    + "\n        </label>\n    </div>\n    <div class=\"input-group\">\n        <label for=\"expires_in\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invitation expires after",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":58},"end":{"line":31,"column":91}}}))
    + "</label>\n        <select id=\"expires_in\" name=\"expires_in\" class=\"invite-user-select modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"expires_in_options"),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":35,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n        <p class=\"time-input-formatted-description\"></p>\n        <div id=\"custom-invite-expiration-time\" class=\"dependent-settings-block custom-time-input-container\">\n            <label class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":45},"end":{"line":39,"column":65}}}))
    + "</label>\n            <input id=\"custom-expiration-time-input\" name=\"custom-expiration-time-input\" class=\"custom-time-input-value inline-block\" type=\"text\" autocomplete=\"off\" value=\"\" maxlength=\"3\"/>\n            <select id=\"custom-expiration-time-unit\" name=\"custom-expiration-time-unit\" class=\"custom-time-input-unit invite-user-select modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"time_choices"),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":44,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <p class=\"custom-time-input-formatted-description\"></p>\n        </div>\n    </div>\n    <div class=\"input-group\">\n        <label for=\"invite_as\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Users join as",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":57},"end":{"line":50,"column":79}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("./help_link_widget.hbs"),undefined,{"name":"help_link_widget","hash":{"link":"/help/user-roles"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n        <select id=\"invite_as\" name=\"invite_as\" class=\"invite-user-select modal_select bootstrap-focus-style\">\n            <option value=\""
    + alias2(alias4(alias3(lookupProperty(lookupProperty(depth0,"invite_as_options"),"guest"), "code", {"start":{"line":54,"column":30},"end":{"line":54,"column":58}} ), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Guests",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":63},"end":{"line":54,"column":78}}}))
    + "</option>\n            <option selected=\"selected\" value=\""
    + alias2(alias4(alias3(lookupProperty(lookupProperty(depth0,"invite_as_options"),"member"), "code", {"start":{"line":55,"column":50},"end":{"line":55,"column":79}} ), depth0))
    + "\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":84},"end":{"line":55,"column":100}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":59,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_owner"),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":62,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n    </div>\n    <div class=\"input-group\">\n        <label>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channels they should join",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":15},"end":{"line":66,"column":49}}}))
    + "</label>\n        <div id=\"streams_to_add\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_select_default_streams_option"),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":12},"end":{"line":76,"column":19}}})) != null ? stack1 : "")
    + "            <div id=\"invite_streams_container\" class=\"add_streams_container\">\n                <div class=\"pill-container stream_picker\">\n                    <div class=\"input\" contenteditable=\"true\"\n                      data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add channels",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":40},"end":{"line":80,"column":61}}}))
    + "\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div id=\"guest_visible_users_container\" class=\"input-group\" style=\"display: none;\">\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_group_pill_container"),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":4},"end":{"line":103,"column":11}}})) != null ? stack1 : "")
    + "</form>\n";
},"6_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":190}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});