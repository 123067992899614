var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active-sub-filter";
},"3":function(container,depth0,helpers,partials,data) {
    return "zero-dm-unreads";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"conversation-partners-icon zulip-icon zulip-icon-dm-groups-3\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "        <span class=\"conversation-partners-icon zulip-icon zulip-icon-"
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":7,"column":72},"end":{"line":7,"column":89}} ), depth0))
    + " "
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":7,"column":94},"end":{"line":7,"column":111}} ), depth0))
    + " user-circle\"></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <i class=\"zulip-icon zulip-icon-bot\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":65},"end":{"line":14,"column":77}}}))
    + "\"></i>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"unread_mention_info\">\n                    @\n                </span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "zero_count";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":11},"end":{"line":1,"column":52}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_zero"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":53},"end":{"line":1,"column":90}}})) != null ? stack1 : "")
    + " dm-list-item bottom_left_row\" data-user-ids-string=\""
    + alias4(alias3(alias2(depth0, "user_ids_string", {"start":{"line":1,"column":145},"end":{"line":1,"column":160}} ), depth0))
    + "\">\n    <div class=\"dm-box dm-user-status\" data-user-ids-string=\""
    + alias4(alias3(alias2(depth0, "user_ids_string", {"start":{"line":2,"column":63},"end":{"line":2,"column":78}} ), depth0))
    + "\" data-is-group=\""
    + alias4(alias3(alias2(depth0, "is_group", {"start":{"line":2,"column":99},"end":{"line":2,"column":107}} ), depth0))
    + "\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_group"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "\n        <a href=\""
    + alias4(alias3(alias2(depth0, "url", {"start":{"line":10,"column":19},"end":{"line":10,"column":22}} ), depth0))
    + "\" class=\"conversation-partners\">\n            <span class=\"conversation-partners-list\">"
    + alias4(alias3(alias2(depth0, "recipients", {"start":{"line":11,"column":55},"end":{"line":11,"column":65}} ), depth0))
    + "</span>\n            "
    + ((stack1 = container.invokePartial(require("./status_emoji.hbs"),lookupProperty(depth0,"status_emoji_info"),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n        <div class=\"dm-markers-and-unreads\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_unread_mention"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "            <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_zero"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":38},"end":{"line":23,"column":70}}})) != null ? stack1 : "")
    + "\">\n                "
    + alias4(alias3(alias2(depth0, "unread", {"start":{"line":24,"column":18},"end":{"line":24,"column":24}} ), depth0))
    + "\n            </span>\n        </div>\n    </div>\n</li>\n";
},"usePartial":true,"useData":true});