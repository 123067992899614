var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"settings-subsection-parent "
    + alias3(alias2(alias1(depth0, "subsection_key", {"start":{"line":6,"column":53},"end":{"line":6,"column":67}} ), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(lookupProperty(depth0,"assigned_permissions"),"length"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":70},"end":{"line":6,"column":124}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"subsection-header\">\n                    <h3>"
    + alias3(alias2(alias1(depth0, "subsection_heading", {"start":{"line":8,"column":26},"end":{"line":8,"column":44}} ), depth0))
    + "</h3>\n                    "
    + ((stack1 = container.invokePartial(require("../settings/settings_save_discard_widget.hbs"),undefined,{"name":"../settings/settings_save_discard_widget","hash":{"show_only_indicator":false},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n\n                <div class=\"subsection-settings\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias4,lookupProperty(depth0,"assigned_permissions"),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":21,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = container.invokePartial(require("../settings/settings_checkbox.hbs"),undefined,{"name":"../settings/settings_checkbox","hash":{"tooltip_message":lookupProperty(depth0,"tooltip_message"),"is_disabled":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"can_edit"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":19,"column":38},"end":{"line":19,"column":52}}}),"label":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(lookupProperty(depths[2],"all_group_setting_labels"),"realm"),lookupProperty(depth0,"setting_name"),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":18,"column":32},"end":{"line":18,"column":90}}}),"is_checked":true,"prefix":"id_group_permission_","setting_name":lookupProperty(depth0,"setting_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./stream_group_permission_settings.hbs"),undefined,{"name":"stream_group_permission_settings","hash":{"id_prefix":lookupProperty(depth0,"id_prefix"),"setting_labels":lookupProperty(lookupProperty(depths[1],"all_group_setting_labels"),"stream"),"assigned_permissions":lookupProperty(depth0,"assigned_permissions"),"stream":lookupProperty(depth0,"stream")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./user_group_permission_settings.hbs"),undefined,{"name":"user_group_permission_settings","hash":{"id_prefix":lookupProperty(depth0,"id_prefix"),"setting_labels":lookupProperty(lookupProperty(depths[1],"all_group_setting_labels"),"group"),"assigned_permissions":lookupProperty(depth0,"assigned_permissions"),"group_name":lookupProperty(depth0,"group_name"),"group_id":lookupProperty(depth0,"group_id")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"group-permissions\">\n    <div class=\"realm-group-permissions group-permissions-section "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"group_has_no_realm_permissions"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":66},"end":{"line":2,"column":115}}})) != null ? stack1 : "")
    + "\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":44}}}))
    + "</h3>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"group_assigned_realm_permissions"),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":24,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"channel-group-permissions group-permissions-section "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"group_assigned_stream_permissions"),"length"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":68},"end":{"line":27,"column":135}}})) != null ? stack1 : "")
    + "\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Channel permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":39}}}))
    + "</h3>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"group_assigned_stream_permissions"),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":37,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"user-group-permissions group-permissions-section "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"group_assigned_user_group_permissions"),"length"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":65},"end":{"line":40,"column":136}}})) != null ? stack1 : "")
    + "\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":41,"column":42}}}))
    + "</h3>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"group_assigned_user_group_permissions"),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":51,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});