var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "settings-subsection-parent";
},"3":function(container,depth0,helpers,partials,data) {
    return "subsection-parent";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("./language_selection_widget.hbs"),undefined,{"name":"language_selection_widget","hash":{"language_code":lookupProperty(depth0,"default_language"),"section_title":lookupProperty(lookupProperty(depth0,"settings_label"),"default_language_settings_label"),"setting_value":lookupProperty(depth0,"default_language_name"),"section_name":"default_language_name"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <option value='"
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":20,"column":34},"end":{"line":20,"column":44}} ), depth0))
    + "'>"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":20,"column":52},"end":{"line":20,"column":68}} ), depth0))
    + "</option>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./settings_numeric_input.hbs"),undefined,{"name":"settings_numeric_input","hash":{"prefix":lookupProperty(depths[1],"prefix"),"render_only":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(lookupProperty(depths[1],"settings_render_only"),"render_only"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":62,"column":26},"end":{"line":62,"column":75}}}),"label":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_label"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":61,"column":20},"end":{"line":61,"column":51}}}),"setting_value":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_object"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":60,"column":28},"end":{"line":60,"column":60}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"general-settings "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":113}}})) != null ? stack1 : "")
    + "\">\n    <!-- this is inline block so that the alert notification can sit beside\n    it. If there's not an alert, don't make it inline-block.-->\n    <div class=\"subsection-header inline-block\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"General",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":28}}}))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":6,"column":93},"end":{"line":6,"column":117}}}),"section_name":"general-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "\n    <div class=\"input-group\">\n        <label for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":17,"column":22},"end":{"line":17,"column":28}} ), depth0))
    + "twenty_four_hour_time\" class=\"settings-field-label\">"
    + alias2(alias4(alias3(lookupProperty(depth0,"settings_label"), "twenty_four_hour_time", {"start":{"line":17,"column":85},"end":{"line":17,"column":121}} ), depth0))
    + "</label>\n        <select name=\"twenty_four_hour_time\" class=\"setting_twenty_four_hour_time prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":18,"column":140},"end":{"line":18,"column":146}} ), depth0))
    + "twenty_four_hour_time\" data-setting-widget-type=\"string\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"twenty_four_hour_time_values"),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":21,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n    </div>\n    <div class=\"input-group\">\n        <label for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":25,"column":22},"end":{"line":25,"column":28}} ), depth0))
    + "color_scheme\" class=\"settings-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":73},"end":{"line":25,"column":87}}}))
    + "</label>\n        <div id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":26,"column":19},"end":{"line":26,"column":25}} ), depth0))
    + "color_scheme\" class=\"tab-picker prop-element\" data-setting-widget-type=\"radio-group\">\n            <input type=\"radio\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":27,"column":38},"end":{"line":27,"column":44}} ), depth0))
    + "theme_select_automatic\" class=\"tab-option setting_color_scheme\" data-setting-widget-type=\"number\" name=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":27,"column":152},"end":{"line":27,"column":158}} ), depth0))
    + "theme_select\" value=\""
    + alias2(alias4(alias3(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"automatic"), "code", {"start":{"line":27,"column":183},"end":{"line":27,"column":217}} ), depth0))
    + "\" />\n            <label class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":28,"column":81},"end":{"line":28,"column":87}} ), depth0))
    + "theme_select_automatic\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select automatic theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":125},"end":{"line":28,"column":156}}}))
    + "\" data-tooltip-template-id=\"automatic-theme-template\" tabindex=\"0\">\n                <i class=\"zulip-icon zulip-icon-monitor\" aria-hidden=\"true\"></i>\n            </label>\n            <input type=\"radio\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":31,"column":38},"end":{"line":31,"column":44}} ), depth0))
    + "theme_select_light\" class=\"tab-option setting_color_scheme\" data-setting-widget-type=\"number\" name=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":31,"column":148},"end":{"line":31,"column":154}} ), depth0))
    + "theme_select\" value=\""
    + alias2(alias4(alias3(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"light"), "code", {"start":{"line":31,"column":179},"end":{"line":31,"column":209}} ), depth0))
    + "\" />\n            <label class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":32,"column":81},"end":{"line":32,"column":87}} ), depth0))
    + "theme_select_light\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":121},"end":{"line":32,"column":148}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":170},"end":{"line":32,"column":190}}}))
    + "\" tabindex=\"0\">\n                <i class=\"zulip-icon zulip-icon-sun\" aria-hidden=\"true\"></i>\n            </label>\n            <input type=\"radio\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":35,"column":38},"end":{"line":35,"column":44}} ), depth0))
    + "theme_select_dark\" class=\"tab-option setting_color_scheme\" data-setting-widget-type=\"number\" name=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":35,"column":147},"end":{"line":35,"column":153}} ), depth0))
    + "theme_select\" value=\""
    + alias2(alias4(alias3(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"dark"), "code", {"start":{"line":35,"column":178},"end":{"line":35,"column":207}} ), depth0))
    + "\" />\n            <label class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":36,"column":81},"end":{"line":36,"column":87}} ), depth0))
    + "theme_select_dark\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Select dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":120},"end":{"line":36,"column":146}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":168},"end":{"line":36,"column":187}}}))
    + "\" tabindex=\"0\">\n                <i class=\"zulip-icon zulip-icon-moon\" aria-hidden=\"true\"></i>\n            </label>\n            <span class=\"slider\"></span>\n        </div>\n    </div>\n\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"enter_sends"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"enter_sends"),"setting_name":"enter_sends"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"dense_mode"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"dense_mode"),"setting_name":"dense_mode"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"information-density-settings\">\n        <div class=\"title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Information density settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":27},"end":{"line":56,"column":63}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(lookupProperty(depth0,"information_density_settings"),"settings"),"user_preferences"),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":64,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});