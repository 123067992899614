var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hidden_by_filters";
},"3":function(container,depth0,helpers,partials,data) {
    return "icon-collapsed-state";
},"5":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("./inbox_row.hbs"),lookupProperty(depth0,"1"),{"name":"inbox_row","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"inbox-dm-header\" tabindex=\"0\" class=\"inbox-header "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"has_dms_post_filter"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":59},"end":{"line":1,"column":118}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"inbox-focus-border\">\n        <div class=\"inbox-left-part-wrapper\">\n            <div class=\"collapsible-button\"><i class=\"zulip-icon zulip-icon-arrow-down toggle-inbox-header-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_dms_collapsed"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":112},"end":{"line":4,"column":163}}})) != null ? stack1 : "")
    + "\"></i></div>\n            <div class=\"inbox-left-part\">\n                <div tabindex=\"0\" class=\"inbox-header-name\">\n                    <div class=\"inbox-header-name-focus-border\">\n                        <i class=\"zulip-icon zulip-icon-user\"></i>\n                        <a tabindex=\"-1\" role=\"button\" href=\"/#narrow/is/private\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Direct messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":82},"end":{"line":9,"column":105}}}))
    + "</a>\n                    </div>\n                </div>\n                <span class=\"unread_mention_info tippy-zulip-tooltip\n                  "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"has_unread_mention"),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":18},"end":{"line":13,"column":65}}})) != null ? stack1 : "")
    + "\"\n                  data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have unread mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":38},"end":{"line":14,"column":71}}}))
    + "\">@</span>\n                <div class=\"unread-count-focus-outline\" tabindex=\"0\">\n                    <span class=\"unread_count tippy-zulip-tooltip on_hover_all_dms_read\"\n                      data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":42},"end":{"line":17,"column":63}}}))
    + "\"\n                      aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":34},"end":{"line":18,"column":55}}}))
    + "\">"
    + alias2(container.lambda(container.strict(depth0, "unread_dms_count", {"start":{"line":18,"column":59},"end":{"line":18,"column":75}} ), depth0))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n<div id=\"inbox-direct-messages-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"dms_dict"),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":27,"column":13}}})) != null ? stack1 : "")
    + "</div>\n\n<div id=\"inbox-streams-container\">\n    "
    + ((stack1 = container.invokePartial(require("./inbox_stream_container.hbs"),depth0,{"name":"inbox_stream_container","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});