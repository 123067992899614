var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "custom_classes", {"start":{"line":1,"column":39},"end":{"line":1,"column":53}} ), depth0))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"custom_classes"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":15},"end":{"line":1,"column":63}}})) != null ? stack1 : "")
    + "icon-button icon-button-"
    + alias3(alias2(alias1(depth0, "intent", {"start":{"line":1,"column":89},"end":{"line":1,"column":95}} ), depth0))
    + "\" tabindex=\"0\">\n    <i class=\"zulip-icon zulip-icon-"
    + alias3(alias2(alias1(depth0, "icon", {"start":{"line":2,"column":38},"end":{"line":2,"column":42}} ), depth0))
    + "\"></i>\n</button>\n";
},"useData":true});