var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "surround-formatting-buttons-row";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"message-edit-feature-group compose-scrolling-buttons-container\">\n                        "
    + ((stack1 = container.invokePartial(require("./compose_control_buttons.hbs"),depth0,{"name":"compose_control_buttons","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        <button type=\"button\" class=\"formatting-control-scroller-button formatting-scroller-forward\">\n                            <i class=\"scroller-forward-icon zulip-icon zulip-icon-compose-scroll-right\"></i>\n                        </button>\n                        <button type=\"button\" class=\"formatting-control-scroller-button formatting-scroller-backward\">\n                            <i class=\"scroller-backward-icon zulip-icon zulip-icon-compose-scroll-left\"></i>\n                        </button>\n                    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"message_edit_save_container\">\n                                <button type=\"button\" class=\"message-actions-button message_edit_save\">\n                                    <img class=\"loader\" alt=\"\" src=\"\" />\n                                    <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":42},"end":{"line":36,"column":55}}}))
    + "</span>\n                                </button>\n                            </div>\n                            <button type=\"button\" class=\"message-actions-button message_edit_cancel\"><span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":107},"end":{"line":39,"column":122}}}))
    + "</span></button>\n                            <span class=\"tippy-zulip-tooltip message-limit-indicator\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Maximum message length: {max_message_length} characters",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":106},"end":{"line":40,"column":170}}}))
    + "\"></span>\n                            <div class=\"message-edit-timer\">\n                                <span class=\"message_edit_countdown_timer\n                                  tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This organization is configured to restrict editing of message content to {minutes_to_edit} minutes after it is sent.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":75},"end":{"line":43,"column":201}}}))
    + "\"></span>\n                            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"message-actions-button message_edit_close\"><span>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":106},"end":{"line":46,"column":120}}}))
    + "</span></button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message_edit\">\n    <div class=\"message_edit_form\">\n        <form id=\"edit_form_"
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":4,"column":30},"end":{"line":4,"column":40}} ), depth0))
    + "\">\n            <div class=\"edit_form_banners\"></div>\n            <div class=\"edit-controls edit-content-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_editable"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":61},"end":{"line":6,"column":118}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"message-edit-textbox\">\n                    <span class=\"copy_message copy-button copy-button-square tippy-zulip-tooltip\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy and close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":118},"end":{"line":8,"column":141}}}))
    + "\" aria-label=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"Copy and close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":155},"end":{"line":8,"column":178}}}))
    + "\" role=\"button\">\n                        <i class=\"zulip-icon zulip-icon-copy\" aria-hidden=\"true\"></i>\n                    </span>\n                    <textarea class=\"message_edit_content message-textarea\">"
    + alias3(alias2(alias1(depth0, "content", {"start":{"line":11,"column":78},"end":{"line":11,"column":85}} ), depth0))
    + "</textarea>\n                </div>\n                <div class=\"scrolling_list preview_message_area\" id=\"preview_message_area_"
    + alias3(alias2(alias1(depth0, "message_id", {"start":{"line":13,"column":92},"end":{"line":13,"column":102}} ), depth0))
    + "\" style=\"display:none;\">\n                    <div class=\"markdown_preview_spinner\"></div>\n                    <div class=\"preview_content rendered_markdown\"></div>\n                </div>\n            </div>\n            <div class=\"action-buttons\">\n                <div class=\"controls edit-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_editable"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":30,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"message-edit-buttons-and-timer\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_editable"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":47,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </form>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});