var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"default"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":60}}})) != null ? stack1 : "")
    + " value=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":6,"column":70},"end":{"line":6,"column":80}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":6,"column":86},"end":{"line":6,"column":102}} ), depth0))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option value=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":15,"column":37},"end":{"line":15,"column":46}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":15,"column":52},"end":{"line":15,"column":68}} ), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"realm-data-deletion-form\">\n    <div class=\"input-group\">\n        <label for=\"delete-realm-data-in\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"After how much time should all data for this organization be permanently deleted (users, channels, messages, etc.)?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":68},"end":{"line":3,"column":192}}}))
    + "</label>\n        <select id=\"delete-realm-data-in\" name=\"delete-realm-data-in\" class=\"modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"delete_in_options"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":7,"column":21}}})) != null ? stack1 : "")
    + "        </select>\n        <p class=\"time-input-formatted-description\"></p>\n        <div id=\"custom-realm-deletion-time\" class=\"dependent-settings-block custom-time-input-container\">\n            <label class=\"modal-field-label\">"
    + alias2(container.lambda(container.strict(depth0, "custom_deletion_input_label", {"start":{"line":11,"column":47},"end":{"line":11,"column":74}} ), depth0))
    + "</label>\n            <input id=\"custom-deletion-time-input\" name=\"custom-deletion-time-input\" class=\"custom-time-input-value inline-block modal_text_input\" type=\"text\" autocomplete=\"off\" value=\"\" maxlength=\"4\"/>\n            <select id=\"custom-deletion-time-unit\" name=\"custom-deletion-time-unit\" class=\"custom-time-input-unit bootstrap-focus-style modal_select\" >\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"time_choices"),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":16,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <p class=\"custom-time-input-formatted-description\"></p>\n        </div>\n    </div>\n</form>\n<p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Are you sure you want to deactivate this organization? All users will lose access to their Zulip accounts.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":3},"end":{"line":22,"column":118}}}))
    + "</p>\n";
},"useData":true});