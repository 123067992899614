var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"change_email_form\">\n    <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"You will receive a confirmation email at the new address you enter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":82}}}))
    + "</p>\n    <label for=\"change-email-form-input-email\" class=\"modal-field-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"New email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":73},"end":{"line":3,"column":91}}}))
    + "</label>\n    <input id=\"change-email-form-input-email\" type=\"text\" name=\"email\" class=\"modal_text_input\" value=\""
    + alias2(container.lambda(container.strict(depth0, "delivery_email", {"start":{"line":4,"column":105},"end":{"line":4,"column":119}} ), depth0))
    + "\" autocomplete=\"off\" spellcheck=\"false\" autofocus=\"autofocus\"/>\n</form>\n";
},"useData":true});