var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"add_member_button_wrapper inline-block\">\n            <button type=\"submit\" name=\"add_member\" class=\"button add-member-button add-users-button small rounded sea-green\" tabindex=\"0\">\n                "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":28}}}))
    + "\n            </button>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"add_members_container add-button-container\">\n    <div class=\"pill-container person_picker\">\n        <div class=\"input\" contenteditable=\"true\"\n          data-placeholder=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Add users or groups. Use #channelname to add all subscribers.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":28},"end":{"line":4,"column":98}}}))
    + "\"></div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"hide_add_button"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":31}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});