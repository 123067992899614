var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"popover-group-menu-description\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "group_description", {"start":{"line":10,"column":66},"end":{"line":10,"column":83}} ), depth0))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item text-item italic\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":18,"column":23}}})) != null ? stack1 : "")
    + "            </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                {members_count, plural, =1 {1 member} other {# members}}\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"popover-menu-list-item text-item italic hidden-for-spectators\">\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This group has been deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":49},"end":{"line":23,"column":90}}}))
    + "</span>\n            </li>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <ul class=\"popover-menu-list popover-group-menu-member-list\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"displayed_subgroups"),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":35,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"displayed_members"),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":45,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"display_all_subgroups_and_members"),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":20},"end":{"line":68,"column":31}}})) != null ? stack1 : "")
    + "                </ul>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <li class=\"popover-group-menu-member\">\n                            <i class=\"popover-group-member-icon popover-menu-icon zulip-icon zulip-icon-triple-users\" aria-hidden=\"true\"></i>\n                            <span class=\"popover-group-menu-member-name\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "name", {"start":{"line":33,"column":75},"end":{"line":33,"column":79}} ), depth0))
    + "</span>\n                        </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"popover-group-menu-member\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":38,"column":28},"end":{"line":42,"column":35}}})) != null ? stack1 : "")
    + "                            <span class=\"popover-group-menu-member-name\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "full_name", {"start":{"line":43,"column":75},"end":{"line":43,"column":84}} ), depth0))
    + "</span>\n                        </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                                <i class=\"popover-group-member-icon zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                                <span class=\"popover-group-member-icon user-circle zulip-icon zulip-icon-"
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":41,"column":107},"end":{"line":41,"column":124}} ), depth0))
    + " "
    + alias3(alias2(alias1(depth0, "user_circle_class", {"start":{"line":41,"column":129},"end":{"line":41,"column":146}} ), depth0))
    + " popover-group-menu-user-presence hidden-for-spectators\" data-tippy-content=\""
    + alias3(alias2(alias1(depth0, "user_last_seen_time_status", {"start":{"line":41,"column":227},"end":{"line":41,"column":253}} ), depth0))
    + "\"></span>\n";
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"popover-group-menu-member\">\n                            <span class=\"popover-group-menu-member-name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_system_group"),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(26, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":49,"column":32},"end":{"line":65,"column":39}}})) != null ? stack1 : "")
    + "                            </span>\n                        </li>\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"has_bots"),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.program(24, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":50,"column":36},"end":{"line":60,"column":43}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{"class":"popover-group-menu-member"},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":40},"end":{"line":55,"column":47}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            View all <z-link-users>users</z-link-users> and <z-link-bots>bots</z-link-bots>\n                                            \n                                            \n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"#organization/users\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"#organization/bot-list-admin\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <a href=\"#organization/users\" role=\"menuitem\">\n                                            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View all users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":44},"end":{"line":58,"column":66}}}))
    + "\n                                        </a>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <a href=\""
    + alias1(container.lambda(container.strict(depth0, "group_members_url", {"start":{"line":62,"column":47},"end":{"line":62,"column":64}} ), depth0))
    + "\" role=\"menuitem\">\n                                        "
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"View all members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":40},"end":{"line":63,"column":64}}}))
    + "\n                                    </a>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"popover-group-menu-placeholder\"><i>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This group has no members.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":64},"end":{"line":71,"column":98}}}))
    + "</i></span>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"separator\" class=\"popover-menu-separator hidden-for-spectators\"></li>\n            <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                <a href=\""
    + alias1(container.lambda(container.strict(depth0, "group_edit_url", {"start":{"line":77,"column":27},"end":{"line":77,"column":41}} ), depth0))
    + "\" role=\"menuitem\" class=\"navigate-link-on-enter popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-user-cog\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Group settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":53},"end":{"line":79,"column":76}}}))
    + "</span>\n                </a>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu user-group-info-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"link-item popover-menu-list-item\">\n            <div class=\"popover-group-menu-info\">\n                <div class=\"popover-group-menu-name-container\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-triple-users\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-group-menu-name\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "group_name", {"start":{"line":7,"column":60},"end":{"line":7,"column":70}} ), depth0))
    + "</span>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"group_description"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":11,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(lookupProperty(depth0,"displayed_members"),"length"),lookupProperty(lookupProperty(depth0,"displayed_subgroups"),"length"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":14,"column":14},"end":{"line":14,"column":70}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"deactivated"),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"popover-menu-list-item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(lookupProperty(depth0,"displayed_members"),"length"),lookupProperty(lookupProperty(depth0,"displayed_subgroups"),"length"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":28,"column":18},"end":{"line":28,"column":74}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(28, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":72,"column":19}}})) != null ? stack1 : "")
    + "        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"is_guest"),lookupProperty(depth0,"is_system_group"),lookupProperty(depth0,"deactivated"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":74,"column":18},"end":{"line":74,"column":59}}}),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":8},"end":{"line":82,"column":19}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"19_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-users"],"data":data,"loc":{"start":{"line":53,"column":44},"end":{"line":53,"column":136}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-bots"],"data":data,"loc":{"start":{"line":54,"column":44},"end":{"line":54,"column":144}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});